<template>
  <div class="document-viewer md:mx-12 md:py-10 mx-auto" v-if="pages">
    <flipbook class="flipbook" :pages="pages" :zooms="null"></flipbook>
  </div>
</template>

<script>
import Flipbook from "flipbook-vue";
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";

export default {
  name: "DocumentViewer",
  props: ["id"],
  components: { Flipbook },
  mounted() {
    ApiService.get(
      apiResource.onboardingKit.experiences.documentLibrary.getDocumentDetails,
      {
        document_id: this.id,
      }
    )
      .then(({ data }) => {
        this.pages = data.data.pages;
      })
      .catch(() => {});
  },
  data() {
    return {
      pages: null,
    };
  },
};
</script>

<style scoped>
.flipbook {
  width: 90vw;
  height: 90vh;
}
</style>
